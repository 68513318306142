h4.job-sub-header {
    margin-top: 15px;
    margin-bottom: 0px;
}

.headerbg {
    box-sizing: content-box;
    float: none;
    position: absolute;
    top: 0; 
    left: 0;
    width: 100%;
    height: 256px;
    background-color: black;
}